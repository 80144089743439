;import $ from 'jquery';
window.$ = window.jQuery = $;
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import 'jquery.scrollto';
import '../vendor/ba-debug.min.js';
import '../vendor/slick.min.js';

(function($){
//------------------------------------------------------------------------------------------------------------------------
    // Page constructor

    // Page constructor
    window.Page = {

//------------------------------------------------------------------------------------------------------------------------
    // Global variables

        // Responsive breakpoints
        mobileMaxWidth: 767.98,
        tabletMaxWidth: 1023.98,

        // scrollTo
        scrollTo: {
            axis: 'y',
            duration: 500,
            offset: {
                left: 0,
                top: -112
            }
        },

        // Modal
        modal: {
            messages: false,
            title: false
        },

        // Animation
        animation: 300,
        hideAnimation: 3000,
        playControlsTimer: null,

//------------------------------------------------------------------------------------------------------------------------
    // Application

        // Set layout
        setLayout: function(){
            // Load videos
            window.Page.loadVideos();

            // Load scrollTo
            window.Page.loadScrollTo();

            // Timeline
            window.Page.loadTimeline();

            // Load more 
            window.Page.loadMoreMediaStudies();

            // Load modals
            window.Page.loadModals();

            // Slick slider
            window.Page.slickSlider();

            // Testimonials slider
            window.Page.testimonialsSlider();

            // Custom select
            window.Page.customSelect();

            // Navigation
            window.Page.subMenu();

            // Application forms
            window.Page.applicationForm();
        },

        // Layout loaded
        layoutLoaded: function(){
            // Start manifesto video
            window.Page.startVideo($('#video'));
        },

        // Resize layout
        resizeLayout: function(){
            // Sticky navbar
            window.Page.stickyHeader();
        },

//------------------------------------------------------------------------------------------------------------------------
    // Videos

        // Start video
        startVideo: function($container){
            var $video = $('video', $container);
            if($video.length){
                $video.prop('muted', true);
                var promise = $video[0].play();

                // Is autoplay enabled?
                if(promise !== undefined){
                    // It is
                    promise.then(_ => {
                        window.Page.hidePlayControls($container);
                    })
                    // It is not
                    .catch(error => {
                        window.Page.showPlayControls($container);
                    });
                }
            }
        },

        // Load videos
        loadVideos: function(){
            $('.video').each(function(){
                var $container = $(this);
                var $video = $('video', $container);

                if($video.length){
                    // Clicked on mute button
                    $container.on('click', '.btn-mute', function(e){
                        e.preventDefault();
                        if($video.prop('muted')){
                            window.Page.unmuteVideo($video, $container);
                        }
                        else {
                            window.Page.muteVideo($video, $container);
                        }
                    });

                    // Clicked on replay button
                    $container.on('click', '.btn-video, .btn-replay:visible', function(e){
                        e.preventDefault();
                        window.Page.replayVideo($video, $container);
                        window.Page.hidePlayControls($container);
                    });

                    // Clicked on play button
                    $container.on('click', '.btn-play:visible', function(e){
                        e.preventDefault();
                        window.Page.playVideo($video, $container);
                        window.Page.hidePlayControls($container);
                    });

                    // Clicked on pause button
                    $container.on('click', '.btn-pause:visible', function(e){
                        e.preventDefault();
                        window.Page.pauseVideo($video, $container);
                        window.Page.showPlayControls($container);
                    });

                    // Show video controls when moving mouse over video
                    $container.on('mousemove', function(e){
                        window.Page.showPlayControls($container);
                    });

                    // Hide video controls when mouse leaves video
                    $container.on('mouseleave', function(e){
                        window.Page.hidePlayControls($container);
                    });

                    // Video is paused
                    $video.on('pause', function(e){
                        window.Page.hidePause($container);
                    });

                    // Video started to play
                    $video.on('play', function(e){
                        window.Page.showPause($container);
                    });
                }
            });
        },

        // Play video
        playVideo: function($video, $container){
            window.Page.unmuteVideo($video, $container);
            $video[0].play();
        },

        // Restart video
        replayVideo: function($video, $container){
            $video[0].pause();
            if($video[0].readyState === 4){
                $video[0].currentTime = 0;
            }
            window.Page.unmuteVideo($video, $container);
            $video[0].play();
        },

        // Puase video
        pauseVideo: function($video, $container){
            $video[0].pause();
        },

        // Mute video
        muteVideo: function($video, $container){
            var $btnMute = $('.btn-mute', $container);
            $video.prop('muted', true);
            $btnMute.addClass('muted');
        },

        // Unmute video
        unmuteVideo: function($video, $container){
            var $btnMute = $('.btn-mute', $container);
            $video.prop('muted', false);
            $btnMute.removeClass('muted');
        },

        // Show pause button, hide play button
        showPause: function($container){
            $('.btn-pause', $container).show();
            $('.btn-play', $container).hide();
        },

        // Hide pause button, show play button
        hidePause: function($container){
            $('.btn-pause', $container).hide();
            $('.btn-play', $container).show();
        },

        // Show video controls
        showPlayControls: function($container){
            if(window.Page.playControlsTimer){
                clearTimeout(window.Page.playControlsTimer);
            }
            window.Page.fadeInPlayControls($container);

            // Hide video controls after a time of inactivity
            window.Page.playControlsTimer = setTimeout(window.Page.fadeOutPlayControls, window.Page.hideAnimation, $container);
        },

        // Hide video controls
        hidePlayControls: function($container){
            if(window.Page.playControlsTimer){
                clearTimeout(window.Page.playControlsTimer);
            }
            window.Page.fadeOutPlayControls($container);
        },

        // Animation for showing controls
        fadeInPlayControls: function($container){
            $('.play-controls:hidden', $container).css({ display: 'flex' }).hide().fadeIn(window.Page.animation);
        },

        // Animation for hiding controls
        fadeOutPlayControls: function($container){
            $('.play-controls', $container).fadeOut(window.Page.animation);
        },

//------------------------------------------------------------------------------------------------------------------------
    // Media studies

        // Timeline
        loadTimeline: function(){
            $('a', '.timeline').on('click', function(e){
                $('a', '.timeline').removeClass('active');
                $(this).addClass('active');
            });
        },

        // Load more media
        loadMoreMediaStudies: function(){
            $('.load-more').on('click', function(e){
                var $target = $(this);
                $.ajax({
                    type: 'POST',
                    url: '/wp-admin/admin-ajax.php',
                    dataType: 'html',
                    data: {
                        action: 'media_load_more',
                        year: $target.attr('data-year'),
                    },
                    success: function(res){
                        $target.parents('.items').append(res);
                        $target.remove();
                    }
                });
            });
        },

//------------------------------------------------------------------------------------------------------------------------
    // Event spaces

        // Slick slider
        slickSlider: function(){
            $('.gallery').slick({
                mobileFirst: false,
                centerMode: true,
                centerPadding: '100px',
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                arrows: true,
                dots: false,
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            centerMode: false,
                            
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            centerMode: false,
                            mobileFirst: true
                            
                        }
                    }
                ]
            });
        },

//------------------------------------------------------------------------------------------------------------------------
    // Testimonials - School of big ideas

        // Testimonials slider
        testimonialsSlider: function(){
            $('.testimonials-container').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                arrows: false,
                dots: true,
                autoplay: true,
                autoplaySpeed: 5000
            });
        },

//------------------------------------------------------------------------------------------------------------------------
    // Navigation submenu

        // Navigation submenu
        subMenu: function(){

            $(".nav-link[href='#']").on("click", function(e){
                e.preventDefault();
                $('.navbar-nav-sub').addClass('show')
            });

            $(document).on('click', function (e) {
                e.stopPropagation();
                if (!e.target.matches(".nav-link[href='#']")) {
                    $('.navbar-nav-sub').removeClass('show');
                }
            })
        },

//------------------------------------------------------------------------------------------------------------------------
    // Application form

        // Application form
        applicationForm: function(){

            $('.acf-button').on('click', function(){
                if(document.querySelector('.acf-form').checkValidity()){
                    setTimeout(function(){
                        $('.acf-button').prop('disabled', true);
                        $('.acf-button').html('Submitting...');
                    },200);
                }
            });
        },

//------------------------------------------------------------------------------------------------------------------------
    // Application form

        // Custom select
        customSelect: function(){
            var x, i, j, l, ll, selElmnt, a, b, c;
            
            x = document.getElementsByClassName("af-field-type-select");
            l = x.length;
            for (i = 0; i < l; i++) {
            selElmnt = x[i].getElementsByTagName("select")[0];
            ll = selElmnt.length;
            
            a = document.createElement("DIV");
            a.setAttribute("class", "select-selected");
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
            x[i].appendChild(a);
            
            b = document.createElement("DIV");
            b.setAttribute("class", "select-items select-hide");
            for (j = 1; j < ll; j++) {
                
                c = document.createElement("DIV");
                c.innerHTML = selElmnt.options[j].innerHTML;
                c.addEventListener("click", function(e) {
                    
                    var y, i, k, s, h, sl, yl;
                    s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                    sl = s.length;
                    h = this.parentNode.previousSibling;
                    for (i = 0; i < sl; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        yl = y.length;
                        for (k = 0; k < yl; k++) {
                        y[k].removeAttribute("class");
                        }
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                    }
                    h.click();
                });
                b.appendChild(c);
            }
            x[i].appendChild(b);
            a.addEventListener("click", function(e) {
                e.stopPropagation();
                closeAllSelect(this);
                this.nextSibling.classList.toggle("select-hide");
                this.classList.toggle("select-arrow-active");
            });
            }

            function closeAllSelect(elmnt) {
                var x, y, i, xl, yl, arrNo = [];
                x = document.getElementsByClassName("select-items");
                y = document.getElementsByClassName("select-selected");
                xl = x.length;
                yl = y.length;
                for (i = 0; i < yl; i++) {
                    if (elmnt == y[i]) {
                    arrNo.push(i)
                    } else {
                    y[i].classList.remove("select-arrow-active");
                    }
                }
                for (i = 0; i < xl; i++) {
                    if (arrNo.indexOf(i)) {
                    x[i].classList.add("select-hide");
                    }
                }
            }

            /* If the user clicks anywhere outside the select box,
            then close all select boxes: */
            document.addEventListener("click", closeAllSelect);
            if($('.acf-button').length && $('.subpage').hasClass('school-of-big-ideas')){
                document.querySelector('.acf-button').setAttribute('onclick',"dataLayer.push({'event': 'sobi_registration'});");
            }
            
        },

//------------------------------------------------------------------------------------------------------------------------
    // scrollTo

        // Sticky navbar
        stickyHeader: function(){
            // There's no sticky navbar when header is fixed
            if($('header').hasClass('fix')){
                return false;
            }

            // Height of the navbar
            var navbarHeight = $('.navbar-collapse', 'header').outerHeight();

            // Position of the navbar's bottom
            var checkpointHideAt = $('.hero', 'header').outerHeight() + navbarHeight;

            // Checkpoints for animation
            var checkpointHidden = checkpointHideAt + navbarHeight;
            var checkpointMinSection = $('section.checkpoint-min').offset();
            var checkpointMin = checkpointMinSection.top;

            // Get sticky when menu is out of view
            if($(window).scrollTop() > checkpointHideAt){
                $('header').addClass('sticky');
            }
            else {
                $('header').removeClass('sticky');
            }

            // Show sticky animation, if we've scrolled over the checkpoint with at least the height of the menu and the 'selected' section is already visible
            if($(window).scrollTop() > checkpointHidden && $(window).scrollTop() + $(window).height() > checkpointMin){
                $('header').addClass('in');
            }
            else {
                $('header').removeClass('in');
            }
        },

        // Load scrollTo
        loadScrollTo: function(){
            // CTA
            $(document).on('click', 'a[href^="#"], a[href^="' + window.location.origin + window.location.pathname + '#"]', function(e){
                e.preventDefault();
                window.Page.scrollToHash(this.hash);

                // Open tab if there's one
                if(!$(this).hasClass('tab-link') && $(this.hash).hasClass('tab-pane')){
                    bootstrap.Tab.getInstance($('[data-bs-target="' + this.hash + '"]').get(0)).show();
                }
            });

            // On load
            if(document.readyState === 'complete'){
                window.Page.scrollToHash(window.location.hash);
            }
            $(window).on('load', function(e){
                window.Page.scrollToHash(window.location.hash);
            });
        },

        // Scroll to content
        scrollToHash: function(hash, params){
            if(!/^\#[0-9a-z_-]+$/gi.test(hash) || !$(hash).is(':visible')){
                return false;
            }

            // Override settings
            params = typeof params !== 'undefined' ? params : {};
            var settings = $.extend(true, {}, window.Page.scrollTo, params);

            // Close navbar on mobile view if open
            var $navbar = $('.navbar-collapse', 'header');
            if($navbar.hasClass('show')){
                // Scroll to content when navbar is closed
                $navbar.one('hidden.bs.collapse', function(e){
                    $(window).scrollTo(hash, settings);
                });
                // Close navbar
                var bsCollapse = new bootstrap.Collapse($navbar.get(0), { toggle: false });
                bsCollapse.hide();
            }
            // Scroll to content immediately
            else {
                $(window).scrollTo(hash, settings);
            }

            // Show hash
            window.location.hash = hash;
        },

//------------------------------------------------------------------------------------------------------------------------
    // Modals

        // Load modals
        loadModals: function(){
            // Load modals
            $('.modal').each(function(i, target){
                var modal = new bootstrap.Modal($(target).get(0));

                // Show default modals
                if($(target).attr('data-show') == 'true'){
                    modal.show();
                }

                // Modal has been open
                $(target).on('shown.bs.modal', function(e){
                    // Bootstrap bugfix: losing autofocus in modals
                    $(':input[autofocus]:first', $(this)).focus();
                });
            });
        },

        // Show modal
        showModal: function(id, params){
            // Override settings
            params = typeof params !== 'undefined' ? params : {};
            var settings = $.extend(true, {}, window.Page.modal, params);
            if(!id) return false;

            // Check if modal exists
            var $modal = $('#modal-' + id);
            if(!$modal.length){
                return false;
            }
            var modal = bootstrap.Modal.getInstance($modal.get(0));
            if(!modal){
                modal = new bootstrap.Modal($modal.get(0));
            }

            // Substitute parameters
            if(params !== false){
                $('.modal-title', $modal).html(settings.title ? settings.title : '');
                $('.modal-body', $modal).empty();
                if(typeof settings.errors !== 'undefined'){
                    window.Page.appendTextAsPs($('.modal-body', $modal), settings.errors);
                }
                else {
                    window.Page.appendTextAsPs($('.modal-body', $modal), settings.messages);
                }
            }

            // Show modal
            modal.show();
        },

        // Show error messages
        showError: function(messages, errors){
            window.Page.showModal('error', { title: 'An error occured.', messages: messages, errors: errors });
        },

        // Append text array as paragraphs
        appendTextAsPs: function($target, text){
            if(!$target.length){
                return false;
            }

            // Convert variable to array
            text = typeof text !== 'undefined' ? text : [];
            if(text.constructor === String){
                text = [text];
            }
            else if(text.constructor === Array){
                text = text;
            }
            else if(typeof text === 'object'){
                var arr = [];
                $.each(text, function(i, value){
                    arr.push(value);
                });
                text = arr;
            }
            else {
                text = [];
            }

            // Append text
            $.each(text, function(i, line){
                $target.append($('<p />').html(line));
            });
        },

//------------------------------------------------------------------------------------------------------------------------

    };

//------------------------------------------------------------------------------------------------------------------------
    // Document ready

    // Document ready
    $(function(){
        // Set layout
        window.Page.setLayout();

        // Resize layout
        window.Page.resizeLayout();
    });

//------------------------------------------------------------------------------------------------------------------------
    // Window loaded

    // Window loaded
    $(window).on('load', function(e){
        // Resize layout
        window.Page.resizeLayout();

        // Layout loaded
        window.Page.layoutLoaded();
    });

//------------------------------------------------------------------------------------------------------------------------
    // Window resized or orientation changed

    // Window resized or orientation changed
    $(window).on('orientationchange resize', function(e){
        // Resize layout
        window.Page.resizeLayout();
    });

//------------------------------------------------------------------------------------------------------------------------
    // Window scrolled

    // Window scrolled
    $(window).on('scroll', function(e){
        // Resize layout
        window.Page.resizeLayout();

        // Show video controls on scroll
        window.Page.showPlayControls();
    });

//------------------------------------------------------------------------------------------------------------------------
})(jQuery);
